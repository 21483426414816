import {
    QueryClient,
    QueryKey,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
// TODO: Remove when we stop using imperative Grid API
import { queryClient } from "../../queryClient"
import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { PatternUtil } from "../../pre-v3/utils/Pattern.util"
import { ApiKeyApi, ApiKeyScope } from "../api/ApiKey.api"
import { BanyanSecurityApi } from "../api/BanyanSecurity.api"
import { PaginationOptions, PaginatedResponse } from "../api/Base.api"
import {
    ConnectorAccessTierRes,
    ConnectorApi,
    ConnectorMetadataReq,
    ConnectorRes,
    ConnectorSearch,
    ConnectorSpecReq,
    ConnectorSpecRes,
    LinuxDeployment,
} from "../api/Connector.api"
import { HostedServiceApi, HostedServiceRes } from "../api/HostedService.api"
import { ClusterHookKey } from "./Cluster.service"
import { convertFromServerTimestamp } from "../../utils/Date.utils"
import { getUrlDomain } from "../../utils/url.utils"

type RegisteredServicesRes = PaginatedResponse<"registered_services", HostedServiceRes>

@Singleton("ConnectorService")
export class ConnectorService {
    public async getConnectorById(id: string): Promise<Connector> {
        const connectorRes = await this.connectorApi.getConnectorById(id)
        const services = await this.getConnectorServices(connectorRes)

        return mapConnectorResToConnector(connectorRes, services)
    }

    public async getConnectorStatusById(id: string): Promise<ConnectorStatus> {
        const { status } = await this.connectorApi.getConnectorById(id)

        return status as ConnectorStatus
    }

    public createConnector(connector: Connector): Promise<Connector> {
        const ConnectorMetadata: ConnectorMetadataReq = getConnectorMetadata(connector)
        const ConnectorSpec: ConnectorSpecReq = getConnectorSpec(connector)
        return this.connectorApi
            .createConnector(ConnectorMetadata, ConnectorSpec)
            .then((response) => mapConnectorResToConnector(response))
    }

    public updateConnector(connector: Connector): Promise<Connector> {
        if (!connector.id) {
            return Promise.reject(this.localization.getString("cannotUpdateAConnectorWithoutAnId"))
        }
        const ConnectorMetadata: ConnectorMetadataReq = getConnectorMetadata(connector)
        const ConnectorSpec: ConnectorSpecReq = getConnectorSpec(connector)
        return this.connectorApi
            .updateConnector(connector.id, ConnectorMetadata, ConnectorSpec)
            .then((response) => mapConnectorResToConnector(response))
    }

    public async deleteConnector(connector: Connector): Promise<void> {
        if (connector.id) {
            await this.connectorApi.deleteConnector(connector.id)
        }
    }
    private connectorApi: ConnectorApi = new ConnectorApi()
    private hostedServiceApi: HostedServiceApi = new HostedServiceApi()
    private localization: LocalizationService = new LocalizationService()

    private async getConnectorServices(connectorRes: ConnectorRes): Promise<Service[]> {
        const options: PaginationOptions = { skip: 0, limit: 100000 }

        const reduceAccessTierPromises = (
            acc: Promise<RegisteredServicesRes>[],
            { access_tier_id, healthy }: ConnectorAccessTierRes
        ): Promise<RegisteredServicesRes>[] => {
            if (!healthy) return acc

            return [
                ...acc,
                this.hostedServiceApi.getHostedServicesByAccessTierId(access_tier_id, options),
            ]
        }

        const [allHostedServices, registeredServicesResBatches] = await Promise.all([
            this.hostedServiceApi.getHostedServices(),
            Promise.all(connectorRes.access_tiers.reduce(reduceAccessTierPromises, [])),
        ])

        const allServicesMap = new Map<string, HostedServiceRes>()

        allHostedServices.forEach((service) => {
            allServicesMap.set(service.ServiceID, service)
        })

        return Array.from(
            registeredServicesResBatches
                .reduce(
                    (acc, registeredServicesRes) =>
                        reduceRegisteredServicesRes(acc, registeredServicesRes, allServicesMap),
                    new Map<string, Service>()
                )
                .values()
        )
    }
}

enum ConnectorHookKey {
    GET_CONNECTORS = "connectorService.getConnectors",
    GET_CONNECTOR_BY_ID = "connectorService.getConnectorById",
    GET_CONNECTOR_STATUS_BY_ID = "connectorService.getConnectorStatusById",
}

function getConnectorByIdKey(id: string): QueryKey {
    return [ConnectorHookKey.GET_CONNECTOR_BY_ID, id]
}

function getConnectorStatusByIdKey(id: string): QueryKey {
    return [ConnectorHookKey.GET_CONNECTOR_STATUS_BY_ID, id]
}

function getConnectorsKey(params?: PaginatedSearch): QueryKey {
    return [ConnectorHookKey.GET_CONNECTORS, params]
}

function helperGetConnectors(
    queryClient: QueryClient,
    params: PaginatedSearch = { skip: 0, limit: 1_000 }
): Promise<Paginated<Connector>> {
    const connectorApi = new ConnectorApi()

    return queryClient.ensureQueryData({
        queryKey: getConnectorsKey(params),
        queryFn: async () => {
            const search: Partial<ConnectorSearch> = {
                skip: params.skip,
                limit: params.limit,
            }

            if (params.filterModel?.displayName) {
                search.display_name = params.filterModel.displayName.filter
            }

            const response = await connectorApi.getConnectors(search)

            return {
                total: response.count,
                data:
                    response.satellites?.map((connectorRes) =>
                        mapConnectorResToConnector(connectorRes)
                    ) ?? [],
            }
        },
    })
}

// TODO: Remove when we stop using imperative Grid API
export function getConnectors(params?: PaginatedSearch): Promise<Paginated<Connector>> {
    return helperGetConnectors(queryClient, params)
}

export function useGetConnectors(
    params?: PaginatedSearch,
    options?: QueryOptions<Paginated<Connector>, unknown>
): UseQueryResult<Paginated<Connector>> {
    const queryClient = useQueryClient()

    const query = useQuery({
        ...options,
        queryKey: getConnectorsKey(params),
        queryFn: () => helperGetConnectors(queryClient, params),
    })

    return {
        ...query,
        refetch: (options) => {
            queryClient.removeQueries([ConnectorHookKey.GET_CONNECTORS])
            return query.refetch(options)
        },
    }
}

// TODO: Remove when we stop using imperative Grid API
export function refreshGetConnectors() {
    queryClient.removeQueries([ConnectorHookKey.GET_CONNECTORS])
}

export function useGetConnectorById(id: string = "", options?: QueryOptions<Connector>) {
    const connectorService = new ConnectorService()

    return useQuery<Connector, string>({
        ...options,
        queryKey: getConnectorByIdKey(id),
        queryFn: () => connectorService.getConnectorById(id),
        enabled: Boolean(id),
    })
}

export function useGetConnectorStatusById(
    id: string = "",
    options?: QueryOptions<ConnectorStatus>
) {
    const connectorService = new ConnectorService()

    return useQuery<ConnectorStatus, string>({
        ...options,
        queryKey: getConnectorStatusByIdKey(id),
        queryFn: () => connectorService.getConnectorStatusById(id),
        enabled: Boolean(id),
    })
}

export function useCreateConnector(options?: QueryOptions<Connector, string, Connector>) {
    const connectorService = new ConnectorService()
    const queryClient = useQueryClient()

    return useMutation<Connector, string, Connector>({
        ...options,
        mutationFn: connectorService.createConnector.bind(connectorService),
        onSuccess: (connector: Connector) => {
            refreshGetConnectors()
            queryClient.invalidateQueries([ClusterHookKey.GET_CLUSTERS])

            options?.onSuccess?.(connector)
        },
    })
}

export function useUpdateConnector(options?: QueryOptions<Connector>) {
    const connectorService = new ConnectorService()
    const queryClient = useQueryClient()

    return useMutation<Connector, string, Connector>({
        ...options,
        mutationFn: connectorService.updateConnector.bind(connectorService),
        onSuccess: (connector: Connector) => {
            if (connector.id) {
                queryClient.setQueryData(getConnectorByIdKey(connector.id), connector)
                queryClient.setQueryData(getConnectorStatusByIdKey(connector.id), connector.status)
            }
            refreshGetConnectors()
            options?.onSuccess?.(connector)
        },
    })
}

export function useDeleteConnector(options?: QueryOptions<void, string, Connector>) {
    const connectorService = new ConnectorService()

    return useMutation<void, string, Connector>({
        ...options,
        mutationFn: connectorService.deleteConnector.bind(connectorService),
        onSuccess: () => {
            refreshGetConnectors()
            options?.onSuccess?.()
        },
    })
}

export function useGetConnectorsStats(options?: QueryOptions<ConnectorsStats>) {
    const connectorApi = new ConnectorApi()
    return useQuery<ConnectorsStats, string>({
        ...options,
        queryKey: ["connectorService.getConnectorsStats"],
        queryFn: async () => {
            const { total, status } = await connectorApi.getConnectorsStats()
            return {
                total,
                statusDictionary: {
                    Pending: status.pending,
                    Reporting: status.healthy,
                    PartiallyReporting: status.partially_healthy,
                    Terminated: status.terminated,
                },
            }
        },
    })
}

export function useGetLatestConnectorVersion(options?: QueryOptions<string>) {
    const banyanSecurityApi = new BanyanSecurityApi()

    return useQuery<string, string>({
        ...options,
        queryKey: ["connectorService.getLatestConnectorVersion"],
        queryFn: async (): Promise<string> => {
            const { latest_versions } = await banyanSecurityApi.getMetadata()
            return latest_versions.connector
        },
    })
}

export function useGetApiKeys(options?: QueryOptions<ApiKey[]>) {
    const apiKeyApi = new ApiKeyApi()

    return useQuery<ApiKey[], string>({
        ...options,
        queryKey: ["connectorService.getApiKeys"],
        queryFn: (): Promise<ApiKey[]> => apiKeyApi.getApiKeys({ scope: ApiKeyScope.SATELLITE }),
    })
}

export function getConnectivityParameters(
    connector: Connector,
    commandCenterUrl: string,
    apiSecretKey: string
): string {
    return `export COMMAND_CENTER_URL=${commandCenterUrl}
export API_KEY_SECRET=${apiSecretKey}
export CONNECTOR_NAME='${connector.name}'`
}
export function getDockerInstallCommand(connector: Connector, version: string): string {
    // Get sanitized docker container name that includes letters, numbers, underscore, dash, period only
    const dockerContainerName =
        PatternUtil.getSanitizedDockerName(connector.name) || "banyan_connector"

    return `sudo -E docker run --name ${dockerContainerName} --privileged --pull always \\
--restart unless-stopped \\
--cap-add=NET_ADMIN -e COMMAND_CENTER_URL -e API_KEY_SECRET \\
-e CONNECTOR_NAME -d gcr.io/banyan-pub/connector:${version}`
}

export function getDockerConnectivityCheckCommand(connector: Connector): string {
    const dockerContainerName =
        PatternUtil.getSanitizedDockerName(connector.name) || "banyan_connector"
    return `sudo docker logs ${dockerContainerName} | grep -q "TCP connection.*succeeded" && echo "Success" || echo`
}

export function getDownloadBinaryCommand(version: string): string {
    return `wget -O connector.tar.gz https://www.banyanops.com/netting/connector-${version}.tar.gz
tar zxf connector.tar.gz`
}

export function getConnectionTestCommand(commandCenterUrl: string, version: string) {
    const domain = getUrlDomain(commandCenterUrl)
    const domainParam = domain ? `--domain "${domain}"` : ""
    return `cd connector-${version}/
./test-connection.sh ${domainParam}`
}

export function getConfigYamlParameters(
    connector: Connector,
    commandCenterUrl: string,
    apiSecretKey: string
): string {
    return `echo 'command_center_url: ${commandCenterUrl}
api_key_secret: ${apiSecretKey}
connector_name: ${connector.name}' > connector-config.yaml`
}

export function getTarballInstallCommand(): string {
    return "sudo ./setup-connector.sh"
}

export function getWindowsConnectorDownloadLink(version: string) {
    return `https://www.banyanops.com/netting/connector-${version}-amd64.exe`
}

export interface Connector {
    id?: string
    name: string
    description?: string
    displayName: string
    hostName?: string
    connectorVersion?: string
    apiKeyId: string
    clusterName: string
    cidrs: string[]
    domains: string[]
    status?: ConnectorStatus
    createdAt?: number
    createdBy?: string
    updatedAt?: number
    updatedBy?: string
    services: Service[]
    accessTierIds: string[]
    lastConnectTime?: Date
    deployment?: LinuxConnectorDeployment | WindowConnectorDeployment
    isSonicOs: boolean
}

interface LinuxConnectorDeployment {
    platform: ServerEnvironments.LINUX
    method: LinuxInstallationMethod
}

interface WindowConnectorDeployment {
    platform: ServerEnvironments.WINDOWS
    method: WindowsInstallationMethod
}

export enum ConnectorStatus {
    /**
     * Initial Status before a Connector starts reporting for the first time
     */
    PENDING = "Pending",
    /**
     * Connector has successfully peered with all of its Access Tiers
     */
    REPORTING = "Reporting",
    /**
     * Connector has successfully peered with some but not all of its Access Tiers
     */
    PARTIALLY_REPORTING = "PartiallyReporting",
    /**
     * Connector is reporting that it can't peer with any of its Access Tiers
     */
    TERMINATED = "Terminated",
}

export function canConnectorBeDeleted(connector: Connector): boolean {
    return (
        !connector.status ||
        ![ConnectorStatus.REPORTING, ConnectorStatus.PARTIALLY_REPORTING].includes(connector.status)
    )
}

interface ConnectorsStats {
    total: number
    statusDictionary: Partial<Record<ConnectorStatus, number>>
}

export interface Service {
    id: string
    name: string
    updatedAt: number
    type?: ServiceType
}

export enum ServiceType {
    WEB = "WEB",
    SSH = "SSH",
    RDP = "RDP",
    KUBERNETES = "KUBERNETES",
    DATABASE = "DATABASE",
    TCP = "TCP",
}

export interface ApiKey {
    id: string
    name: string
}

export enum ServerEnvironments {
    LINUX = "Linux",
    WINDOWS = "Windows",
}

export enum LinuxInstallationMethod {
    DOCKER = "Docker",
    TARBALL = "Tarball",
}

export enum WindowsInstallationMethod {
    EXECUTABLE = "Executable",
}

function mapConnectorResToConnector(
    connectorRes: ConnectorRes,
    services: Service[] = []
): Connector {
    const spec = getConnectorResSpec(connectorRes)
    return {
        id: connectorRes.id,
        name: connectorRes.name,
        description: connectorRes.description || undefined,
        displayName: connectorRes.display_name,
        hostName: connectorRes.host_info?.name,
        connectorVersion: connectorRes.connector_version,
        apiKeyId: connectorRes.api_key_id,
        clusterName: getClusterName(spec?.spec),
        cidrs: connectorRes.cidrs || [],
        domains: connectorRes.domains || [],
        accessTierIds: connectorRes.access_tiers.map(({ access_tier_id }) => access_tier_id),
        status: connectorRes.status as ConnectorStatus,
        createdAt: DateUtil.convertLargeTimestamp(connectorRes.created_at),
        createdBy: connectorRes.created_by,
        updatedAt: DateUtil.convertLargeTimestamp(connectorRes.updated_at),
        updatedBy: connectorRes.updated_by,
        lastConnectTime: connectorRes.last_status_updated_at
            ? convertFromServerTimestamp(connectorRes.last_status_updated_at)
            : undefined,
        deployment: mapConnectorDeployment(spec),
        services,
        isSonicOs: isSonicOs(spec?.spec),
    }
}

function getConnectorResSpec(connectorRes: ConnectorRes): ConnectorSpecRes | undefined {
    try {
        return JSON.parse(connectorRes.spec)
    } catch (e) {
        console.error(e)
    }
}

function isSonicOs(connectorSpec: ConnectorSpecReq | undefined): boolean {
    const deploymentRes = connectorSpec?.deployment
    return deploymentRes?.platform === "SonicOS"
}

const linuxMethodsMap: Record<LinuxDeployment["method"], LinuxInstallationMethod> = {
    Docker: LinuxInstallationMethod.DOCKER,
    Tarball: LinuxInstallationMethod.TARBALL,
}
function mapConnectorDeployment(spec: ConnectorSpecRes | undefined): Connector["deployment"] {
    if (spec?.spec.deployment?.platform === "Windows") {
        return {
            platform: ServerEnvironments.WINDOWS,
            method: WindowsInstallationMethod.EXECUTABLE,
        }
    }
    if (spec?.spec.deployment?.platform === "Linux") {
        return {
            platform: ServerEnvironments.LINUX,
            method: linuxMethodsMap[spec.spec.deployment.method],
        }
    }
}

function getClusterName(connectorSpec: ConnectorSpecReq | undefined): string {
    return connectorSpec?.peer_access_tiers[0].cluster ?? ""
}

function getConnectorMetadata(connector: Connector): ConnectorMetadataReq {
    return {
        name: connector.name || connector.displayName,
        display_name: connector.displayName,
        description: connector.description,
    }
}

const linuxMethodsToReq: Record<LinuxInstallationMethod, LinuxDeployment["method"]> = {
    [LinuxInstallationMethod.DOCKER]: "Docker",
    [LinuxInstallationMethod.TARBALL]: "Tarball",
}

function mapConnectorDeploymentToReq(connector: Connector): ConnectorSpecReq["deployment"] {
    const { deployment } = connector
    if (deployment?.platform === ServerEnvironments.WINDOWS) {
        return {
            platform: "Windows",
            method: "Installer",
        }
    }

    if (deployment?.platform === ServerEnvironments.LINUX) {
        return {
            platform: "Linux",
            method: linuxMethodsToReq[deployment.method],
        }
    }
}

function getConnectorSpec(connector: Connector): ConnectorSpecReq {
    return {
        keepalive: 20,
        api_key_id: connector.apiKeyId,
        cidrs: connector.cidrs,
        domains: connector.domains,
        peer_access_tiers: [
            {
                cluster: connector.clusterName,
                access_tiers: ["*"],
            },
        ],
        disable_snat: false,
        deployment: mapConnectorDeploymentToReq(connector),
    }
}

type ServiceDict = Map<string, Service>
type HostedServiceResDict = Map<string, HostedServiceRes>

function reduceRegisteredServicesRes(
    acc: ServiceDict,
    registeredServicesRes: RegisteredServicesRes,
    allServices: HostedServiceResDict
): ServiceDict {
    return registeredServicesRes.registered_services.reduce(
        (acc, hostedServiceRes) => reduceHostedServiceRes(acc, hostedServiceRes, allServices),
        acc
    )
}

function reduceHostedServiceRes(
    acc: ServiceDict,
    hostedServiceRes: HostedServiceRes,
    allServices: HostedServiceResDict
): ServiceDict {
    if (acc.has(hostedServiceRes.ServiceID)) return acc

    const relevantServiceRes = allServices.get(hostedServiceRes.ServiceID)

    if (!relevantServiceRes) return acc

    acc.set(relevantServiceRes.ServiceID, {
        id: relevantServiceRes.ServiceID,
        name: relevantServiceRes.ServiceName,
        updatedAt: DateUtil.convertLargeTimestamp(relevantServiceRes.LastUpdatedAt),
        type: getServiceType(relevantServiceRes),
    })

    return acc
}

function getServiceType(hostedServiceRes: HostedServiceRes): ServiceType | undefined {
    try {
        const json = JSON.parse(hostedServiceRes.ServiceSpec)

        switch (json.metadata?.tags?.service_app_type) {
            case "WEB":
                return ServiceType.WEB
            case "SSH":
                return ServiceType.SSH
            case "RDP":
                return ServiceType.RDP
            case "K8S":
                return ServiceType.KUBERNETES
            case "DATABASE":
                return ServiceType.DATABASE
            default:
                return ServiceType.TCP
        }
    } catch (error) {
        return undefined
    }
}
