import { BaseApi } from "./Base.api"

export class MomApi extends BaseApi {
    public getOrgs(): Promise<OrgRes[]> {
        return this.get("/api/v2/mom/org", {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public getOrgById(orgId: string): Promise<OrgRes> {
        return this.get(`/api/v2/mom/org/${orgId}`, {
            default: this.localization.getString("errorCouldNotFetchOrg"),
        })
    }

    public addOrg(orgBody: OrgBody): Promise<OrgRes> {
        return this.post("/api/v2/mom/org", orgBody, {
            default: this.localization.getString("errorCouldNotAddOrg"),
            4006: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("organization"),
                orgBody.org_name
            ),
            4018: this.localization.getString("invalidCustomerId"),
        })
    }

    public updateOrg(orgBody: OrgBody): Promise<OrgRes> {
        return this.put("/api/v2/mom/org", orgBody, {
            default: this.localization.getString("errorCouldNotEditOrg"),
            4022: this.localization.getString("editionDowngradeIsNotSupported"),
            4017: this.localization.getString("orgIsProvisioningPleaseWait"),
            4018: this.localization.getString("invalidCustomerId"),
        })
    }

    public deleteOrg(orgId: string): Promise<void> {
        return this.delete(
            `/api/v2/mom/org/${encodeURIComponent(orgId)}`,
            {},
            {
                default: this.localization.getString("errorCouldNotDeleteOrg"),
            }
        )
    }

    public getLicenseInformationByOrgId(orgId: string): Promise<LicenseInformationRes | null> {
        return this.get(`/api/v2/mom/org/${orgId}/licenses`, {
            default: this.localization.getString("failedToGetOrgLicense"),
        })
    }

    public updateOrgSettings(
        orgId: string,
        orgSettingsBody: OrgSettingsBody
    ): Promise<OrgSettingsRes> {
        return this.put(`/api/v2/mom/org/${orgId}/settings`, orgSettingsBody, {
            default: this.localization.getString("errorCouldNotEditOrg"),
        })
    }

    public assignMspOrg(query: AttachMspOrgQuery): Promise<void> {
        const params = new URLSearchParams({ ...query })

        return this.post(`api/v2/mom/msp/child_org?${params.toString()}`, undefined, {
            default: this.localization.getString(
                "failedToAssignSomething",
                this.localization.getString("mspOrg")
            ),
        })
    }

    public removeMspOrgAssignment(query: DetachMspOrgQuery): Promise<void> {
        const params = new URLSearchParams({ ...query })

        return this.delete(`api/v2/mom/msp/child_org?${params.toString()}`, undefined, {
            default: this.localization.getString(
                "failedToRemoveSomething",
                this.localization.getString("mspOrgAssignment")
            ),
        })
    }
}

export interface OrgBody {
    org_name: string
    first_name?: string
    last_name?: string
    email?: string
    edition?: EditionRes
    type?: TypeRes
    customer_id?: string
    global_edge?: boolean
    private_edge?: boolean
    is_ai_assist_enabled: boolean
    is_appdiscovery_enabled: boolean
    archived: boolean
    banyan_idp?: boolean
    is_msp_org: boolean
    is_dns_filter_enabled: boolean
    is_urlfiltering_enabled: boolean
    internal_owner?: string | null
    archive_at?: number | null
}

export interface OrgRes {
    status?: StatusRes
    org_name: string
    org_id: string
    first_name: string
    last_name: string
    email: string
    edition: EditionRes
    type: TypeRes
    last_updated_at: number
    customer_id: string
    global_edge: boolean
    private_edge: boolean
    is_ai_assist_enabled: boolean
    is_dns_filter_enabled: boolean
    is_urlfiltering_enabled: boolean
    is_msp_org: boolean
    is_appdiscovery_enabled: boolean
    is_mysonicwall: boolean
    created_at: number
    created_by: string
    last_updated_by: string
    archived: boolean
    banyan_idp: boolean
    parent_org_id?: string
    provision_status: {
        access_tier?: AccessTierStatusRes
        shield?: ShieldStatusRes
        user_pool?: ProvisionStatusRes
        org_data?: ProvisionStatusRes
        registered_domain?: ProvisionStatusRes
        connector?: ProvisionStatusRes
        invite_code?: ProvisionStatusRes
        user_pool_domain?: ProvisionStatusRes
        user?: ProvisionStatusRes
        default_groups_user?: ProvisionStatusRes
        user_pool_client?: ProvisionStatusRes
        default_groups_user_pool?: ProvisionStatusRes
        update_user_pool?: ProvisionStatusRes
        invite_admin_user?: ProvisionStatusRes
        private_edge_shield?: ProvisionStatusRes
        [key: string]: ProvisionStatusRes | AccessTierStatusRes | undefined
    }
    internal_owner: string | null
    archive_at: number | null
}

export type EditionRes = "Team" | "Enterprise" | "Unlimited"

export type TypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "InternalTemporary"
    | "InternalPersistent"
    | string

export type StatusRes = "Success" | "PartialSuccess" | "InProgress" | "Failed"

export type ProvisionStatusRes = "Success" | "Failed" | "Archived" | "Pending" | "Deleted"

export type AccessTierStatusRes =
    | "Pending"
    | "Provisioning"
    | "ProvisioningFailed"
    | "DNSEntriesFailed"
    | "Success"
    | "Failed"
    | "Deleted"
    | "DeletionFailed"

export type ShieldStatusRes =
    | "Pending"
    | "Provisioning"
    | "ProvisioningFailed"
    | "Success"
    | "Failed"
    | "Deleted"
    | "DeletionFailed"

export interface LicenseInformationRes {
    spa: LicenseTypeRes
    sia: LicenseTypeRes
    spa_users: number
    sia_users: number
    spa_overage_count: number
    sia_overage_count: number
}

export type LicenseTypeRes = "None" | "Basic" | "Advanced"

export interface OrgSettingsBody {
    archive_at: number
}

export interface OrgSettingsRes {
    archive_at: number
}

export interface AttachMspOrgQuery {
    msp_org_id: string
    child_org_id: string
}

export interface DetachMspOrgQuery {
    msp_org_id: string
    child_org_id: string
}
