// Create Query Keys by
// * Using this enum in the first spot
// * Followed by any parameters
// * Ending with the service name
// const queryKey: QueryKey = [ApiFunction.GET_SERVICE_TUNNELS, params, "ServiceTunnelService"]

export enum ApiFunction {
    CREATE_ITP_POLICY = "CREATE_ITP_POLICY",
    DELETE_ITP_POLICY = "DELETE_ITP_POLICY",
    GENERATE_DNS_REPORT = "GENERATE_DNS_REPORT",
    GET_ACCESS_ACTIVITIES = "GET_ACCESS_ACTIVITIES",
    GET_ACCESS_TIERS = "GET_ACCESS_TIERS",
    GET_ACCESS_TIER_BY_ID = "GET_ACCESS_TIER_BY_ID",
    GET_ACCESS_TIER_STATUS_BY_ID = "GET_ACCESS_TIER_STATUS_BY_ID",
    GET_ACCESS_TIER_GROUPS = "GET_ACCESS_TIER_GROUPS",
    GET_ACCESS_TIER_GROUP_BY_ID = "GET_ACCESS_TIER_GROUP_BY_ID",
    GET_ADMIN_INFO = "GET_ADMIN_INFO",
    GET_API_KEYS = "GET_API_KEYS",
    GET_CLUSTERS = "GET_CLUSTERS",
    GET_DEVICES = "GET_DEVICES",
    GET_DNS_FILTERS = "GET_DNS_FILTERS",
    GET_DNS_REPORT = "GET_DNS_REPORT",
    GET_FEATURE_FLAGS = "GET_FEATURE_FLAGS",
    GET_ITP_POLICIES = "GET_ITP_POLICIES",
    GET_ITP_POLICY = "GET_ITP_POLICY",
    GET_ITP_POLICY_SYNC_STATUS = "GET_ITP_POLICY_SYNC_STATUS",
    GET_MSP_ORGS = "GET_MSP_ORGS",
    GET_NETWORKS = "GET_NETWORKS",
    GET_ORG_BY_ID = "GET_ORG_BY_ID",
    GET_ORG_DETAILS = "GET_ORG_DETAILS",
    GET_ORG_LIST = "GET_ORG_LIST",
    GET_PRIVATE_RESOURCE_BY_ID = "GET_PRIVATE_RESOURCE_BY_ID",
    GET_PRIVATE_RESOURCES = "GET_PRIVATE_RESOURCES",
    GET_RESOURCE_IPS = "GET_RESOURCE_IPS",
    GET_ROLE = "GET_ROLE",
    GET_ROLES = "GET_ROLES",
    GET_SERVICE_ACCOUNT_ROLES = "GET_SERVICE_ACCOUNT_ROLES",
    GET_SERVICE_TUNNELS = "GET_SERVICE_TUNNELS",
    GET_SERVICE_TUNNELS_DETAILS = "GET_SERVICE_TUNNELS_DETAILS",
    GET_SERVICE_TUNNEL = "GET_SERVICE_TUNNEL",
    GET_SERVICES_BY_ACCESS_TIER = "GET_SERVICES_BY_ACCESS_TIER",
    GET_USERS = "GET_USERS",
    GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL",
    REORDER_ITP_POLICIES = "REORDER_ITP_POLICIES",
    UPDATE_ITP_POLICY = "UPDATE_ITP_POLICY",
    GET_TEMPLATES = "GET_TEMPLATES",
    GET_POLICIES = "GET_POLICIES",
}
