import React from "react"
import styles from "./GetHelpOverview.module.scss"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { useServiceLinks } from "../../pre-v3/services/link/Link.service"
import { Card } from "../../v3/components/card/Card.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../components/button/Button.component"
import { Icon } from "../../components/icon/Icon.component"
import { useSoftwareVersion } from "../../hooks/useSoftwareVersion.hook"
import useTitle from "../../hooks/useTitle.hook"

interface Props {
    hasCommunityForum: boolean
    hasSupport: boolean
}

export function GetHelpHome(props: Props): JSX.Element {
    useTitle(["home", "getHelp", "adminConsole"])
    const localization = useServiceLocalization()
    const links = useServiceLinks()
    const softwareVersion = useSoftwareVersion()

    return (
        <div className={styles.cardContainer}>
            {props.hasCommunityForum && (
                <GetHelpCard
                    title={localization.getString("communityForum")}
                    description={localization.getString("communityForumDesc")}
                    icon={IconType.USERS}
                    buttonText={localization.getString("chatInOurForum")}
                    buttonLink={links.getLink("slackCommunityForum")}
                />
            )}
            <GetHelpCard
                title={localization.getString("documentation")}
                description={localization.getString("documentationDesc")}
                icon={IconType.BOOK}
                buttonText={localization.getString("checkOutOurDocs")}
                buttonLink={links.getLink("documentation")}
            />
            {props.hasSupport && (
                <GetHelpCard
                    title={localization.getString("support")}
                    description={localization.getString("supportDesc")}
                    icon={IconType.HEADSET}
                    buttonText={localization.getString("contactOurSupport")}
                    buttonLink={links.getLink("supportHome")}
                />
            )}
            <GetHelpCard
                title={localization.getString("sonicWallCseStatus")}
                description={localization.getString("cseStatusDesc")}
                icon={IconType.SIGNAL_ALT}
                buttonText={localization.getString("viewCurrentStatus")}
                buttonLink={links.getLink("banyanStatus")}
            />
            <GetHelpCard
                title={localization.getString("versionNumber")}
                description={localization.getString(
                    "somethingColonSomething",
                    localization.getString("version"),
                    softwareVersion
                )}
                icon={IconType.CODE_BRANCH}
            />
        </div>
    )
}

interface GetHelpCardProps {
    title: string
    description: string
    icon: IconType
    buttonText?: string
    buttonLink?: string
}

function GetHelpCard(props: GetHelpCardProps): JSX.Element {
    const onLinkClick: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.buttonLink && window.open(props.buttonLink, "_blank", "noopener,noreferrer")
    }

    return (
        <Card className={styles.card}>
            <div className={styles.outerContainer}>
                <div className={styles.innerContainer}>
                    <h2 className={styles.title}>{props.title}</h2>
                    <div className={styles.description}>{props.description}</div>
                </div>
                <Icon className={styles.icon} icon={props.icon} />
            </div>
            {props.buttonText && (
                <div className={styles.buttonContainer}>
                    <Button
                        icon={IconType.ARROW_RIGHT}
                        onClick={onLinkClick}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        aria-label={props.buttonText}
                    >
                        {props.buttonText}
                    </Button>
                </div>
            )}
        </Card>
    )
}
