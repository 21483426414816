import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("EndUserApi")
export class EndUserApi extends BaseApi {
    public getUserOrgDetails(): Promise<UserOrgDetailsRes> {
        return this.get("/api/v1/user_org_details", {
            default: this.localization.getString("errorCouldNotFetchUserOrgDetails"),
        })
    }

    public updateIDPConfig(idpConfig: IDPConfigReq): Promise<void> {
        return this.postForm("/api/v1/update_org", idpConfig, {
            default: this.localization.getString("errorFailedToUpdateUserConfig"),
        })
    }

    public switchBanyanIDPToLocalIDP(): Promise<void> {
        return this.post(
            "/api/v2/switch_idp",
            {},
            {
                default: this.localization.getString("failedToSwitchToLocalIdp"),
            }
        )
    }
}

export interface UserOrgDetailsRes {
    IsSCIMEnabled: boolean
    IDPProto: "SAML" | "OIDC"
    IDPConfig: string
    IDPName: string
    IsAdminBanyanIDP: boolean
    OrgName: string
    TrustproviderURL: string
}

export interface IDPConfigRes {
    IssuerURL?: string
    ClientID?: string
    ClientSecret?: string
    RedirectURL?: string
    AccessKeyId?: string
    SecretAccessKey?: string
    UserPoolId?: string
    UserPoolDomain?: string
    SSOURL?: string
    CAData?: string
    UsernameAttr?: string
    EmailAttr?: string
    GroupsAttr?: string
    GroupsDelim?: string
    EntityIssuer?: string
    PWLessConfig?: string
    DeviceRegClientID?: string
    DeviceRegClientSecret?: string
    MetadataURL?: string
}

export interface IDPConfigReq {
    IDPName?: string
    IDPProtocol?: "SAML" | "OIDC"
    IDPConfig?: string
    IDPMetadataURL?: string
}
