import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import { Form } from "../../../pre-v3/components/form/Form"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { formatToLocalDateStr } from "../../../utils/Date.utils"
import { Edition } from "../../../v3/services/shared/Edition"
import { Option } from "../../../v3/components/select-input/SelectInput.component"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"
import {
    FeatureModelType,
    MspOrg,
    Org,
    OrgType,
    isArchivalDateIncrementAllowed,
    useIncrementArchivalDate,
} from "../../../v3/services/MomOrgManagement.service"
import { AiAssistedAdminSearchCheckbox } from "../shared/AiAssistedAdminSearch.component"
import { ApplicationDiscoveryCheckbox } from "../shared/ApplicationDiscovery.component"
import { CustomerIdInput } from "../shared/CustomerId.component"
import { FeatureModelRow } from "../shared/FeatureModel.component"
import { InternalOwnerInput } from "../shared/InternalOwner.component"
import { MspOrgCheckbox } from "../shared/MspOrg.component"
import { NetworkConfigurationCheckbox } from "../shared/NetworkConfiguration.component"
import { OrgIdInput } from "../shared/OrgId.component"
import { OrgNameInput } from "../shared/OrgName.component"
import { OrgTypeInput } from "../shared/OrgType.component"
import { ParentMspInput } from "../shared/ParentMsp.component"
import { SonicWallCseIdpCheckbox } from "../shared/SonicWallCseIdp.component"
import styles from "./OrgSpecification.module.scss"

interface Props {
    org: Org
}

export function OrgSpecification(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)

    const { mutate: incrementArchivalDate, isLoading: isIncrementing } = useIncrementArchivalDate(
        props.org,
        {
            onSuccess: () => {
                successToastRef.current?.openToast(
                    localization.getString("successfullyUpdatedArchivalDate")
                )
            },
            onError: () => {
                errorToastRef.current?.openToast(
                    localization.getString("failedToUpdateArchivalDate")
                )
            },
        }
    )

    const isTeamEdition =
        props.org.featureModel.type === FeatureModelType.EDITION_BASED &&
        props.org.featureModel.edition === Edition.TEAM

    return (
        <React.Fragment>
            <Form className={styles.form} labelWidth={177} display="grid" onSubmit={noOp}>
                <OrgIdInput id={Id.ORG_ID} value={props.org.id} />
                <OrgNameInput id={Id.NAME} value={props.org.name} disabled />
                <MaybeArchivalDateRow
                    org={props.org}
                    isIncrementing={isIncrementing}
                    incrementArchivalDate={incrementArchivalDate}
                />
                <InternalOwnerInput
                    id={Id.INTERNAL_OWNER}
                    value={props.org.internalOwnerEmailAddress}
                    disabled
                />
                {props.org.featureFlags.isMspOrg && (
                    <MspOrgCheckbox
                        id={Id.MSP_ORG}
                        value={props.org.featureFlags.isMspOrg}
                        disabled
                    />
                )}
                <FeatureModelRow org={props.org} editionInputId={Id.EDITION} editionInputDisabled />
                {!props.org.featureFlags.isMspOrg && (
                    <React.Fragment>
                        {!isTeamEdition && (
                            <React.Fragment>
                                <SonicWallCseIdpCheckbox
                                    id={Id.SONIC_WALL_CSE_IDP}
                                    value={props.org.featureFlags.usesSonicWallCseIdp}
                                    disabled
                                />
                                <ApplicationDiscoveryCheckbox
                                    id={Id.APPLICATION_DISCOVERY}
                                    value={props.org.featureFlags.enableApplicationDiscovery}
                                    disabled
                                />
                            </React.Fragment>
                        )}
                        <NetworkConfigurationCheckbox
                            usesGlobalEdge={props.org.networkConfiguration.usesGlobalEdge}
                            usesPrivateEdge={props.org.networkConfiguration.usesPrivateEdge}
                            usesGlobalEdgeDisabled
                            usesPrivateEdgeDisabled
                            showPrivateEdge={!isTeamEdition}
                        />
                        <AiAssistedAdminSearchCheckbox
                            id={Id.AI_ASSISTED_ADMIN_SEARCH}
                            value={props.org.featureFlags.enableAiAssistedAdminSearch}
                            disabled
                        />
                    </React.Fragment>
                )}
                <OrgTypeInput
                    id={Id.ORG_TYPE}
                    value={props.org.typeInformation.type}
                    typeInformation={props.org.typeInformation}
                    disabled
                />
                <CustomerIdInput id={Id.CUSTOMER_ID} value={props.org.customerId} disabled />
                {!props.org.featureFlags.isMspOrg && (
                    <ParentMspInput id={Id.PARENT_MSP} value={props.org.parentMspOrg} disabled />
                )}
            </Form>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

enum Id {
    ORG_ID = "orgId",
    NAME = "name",
    INTERNAL_OWNER = "internalOwner",
    MSP_ORG = "mspOrg",
    EDITION = "edition",
    SONIC_WALL_CSE_IDP = "sonicWallCseIdp",
    APPLICATION_DISCOVERY = "applicationDiscovery",
    AI_ASSISTED_ADMIN_SEARCH = "aiAssistedAdminSearch",
    ORG_TYPE = "orgType",
    CUSTOMER_ID = "customerId",
    PARENT_MSP = "parentMsp",
}

interface MaybeArchivalDateRowProps {
    org: Org
    isIncrementing: boolean
    incrementArchivalDate(date: Date): void
}

function MaybeArchivalDateRow(props: MaybeArchivalDateRowProps): JSX.Element {
    const localization = useServiceLocalization()

    switch (props.org.typeInformation.type) {
        case OrgType.PRODUCTION:
        case OrgType.STAGING:
        case OrgType.INTERNAL_PERSISTENT:
        case OrgType.UNKNOWN:
            return <React.Fragment />

        case OrgType.TRIAL:
        case OrgType.INTERNAL_TEMPORARY:
            const { archiveAt } = props.org.typeInformation

            return (
                <FormLabel title={localization.getString("archivalDate")}>
                    <div className={styles.archivalDateContainer}>
                        {archiveAt ? (
                            <ArchivalDate
                                archiveAt={archiveAt}
                                isIncrementing={props.isIncrementing}
                                incrementArchivalDate={props.incrementArchivalDate}
                            />
                        ) : (
                            "-"
                        )}
                    </div>
                </FormLabel>
            )
    }
}

interface ArchivalDateProps {
    archiveAt: Date
    isIncrementing: boolean
    incrementArchivalDate(date: Date): void
}

function ArchivalDate(props: ArchivalDateProps): JSX.Element {
    const localization = useServiceLocalization()

    const isArchivalDateButtonDisabled = !isArchivalDateIncrementAllowed(
        props.archiveAt,
        new Date()
    )

    return (
        <React.Fragment>
            {formatToLocalDateStr(props.archiveAt, localization.getLocale())}
            <Tooltip
                title={
                    isArchivalDateButtonDisabled
                        ? localization.getString("archivalDateCannotBeIncrementedBy365Days")
                        : ""
                }
            >
                <span>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={(event) => {
                            event.preventDefault()
                            props.incrementArchivalDate(props.archiveAt)
                        }}
                        loading={props.isIncrementing}
                        disabled={isArchivalDateButtonDisabled}
                    >
                        {localization.getString("incrementBy45Days")}
                    </Button>
                </span>
            </Tooltip>
        </React.Fragment>
    )
}

function getMspOption(mspOrg: MspOrg): Option {
    return {
        displayName: mspOrg.name,
        value: mspOrg.id,
    }
}

function noOp(): void {}
