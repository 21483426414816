import React from "react"

import {
    ConfirmationModalWithoutRef,
    ModalAction,
    ModalApi,
} from "../modal/ConfirmationModal.component"
import styles from "./CollapsibleCard.module.scss"
import classnames from "classnames"
import { Icon, IconType } from "../icon/Icon.component"
import { Button, ButtonElement, ButtonType } from "../button/Button.component"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"

export interface CollapsibleCardProps {
    id: string
    title?: string
    children: React.ReactNode
    cardAction?: () => void
    isCardChild?: boolean
    disabled?: boolean
    isOpen?: boolean
    onOpen?: (isOpen: boolean) => void
    removeLabel?: string
    removeMessage?: string
    solidBorder?: boolean
}

export function CollapsibleCard(props: CollapsibleCardProps) {
    const { id, title, children, isCardChild, disabled = false, isOpen = true } = props
    const [cardOpen, setCardOpen] = React.useState(isOpen)
    const localization = useServiceLocalization()

    const modalRef = React.useRef<ModalApi<void>>(null)

    const cardClass = classnames(styles.Card, {
        [styles.CardDotted]: !props.solidBorder && !isCardChild,
        [styles.isOpen]: cardOpen && !isCardChild,
    })

    const cardContentClass = classnames(styles.CardContent, {
        [styles.isOpen]: cardOpen,
        [styles.isChild]: isCardChild && cardOpen,
    })

    const handleOpenModal = async () => {
        const response = await modalRef.current?.open()
        if (response?.action === ModalAction.COMPLETE) props.cardAction?.()
    }

    const handleConfirmRemove = async () => modalRef.current?.complete()

    const titleId = id && `${id}Title`

    const handleCardOpen = (cardOpen: boolean) => {
        setCardOpen(cardOpen)
        props.onOpen?.(isOpen)
    }

    return (
        <fieldset id={id} className={cardClass} aria-labelledby={isCardChild ? titleId : undefined}>
            {!isCardChild && (
                <legend className={styles.CardName}>
                    <button onClick={() => handleCardOpen(!cardOpen)} type="button">
                        {!isCardChild && (
                            <Icon icon={cardOpen ? IconType.CARET_DOWN : IconType.CARET_RIGHT} />
                        )}
                        {title}
                    </button>
                </legend>
            )}

            {props.cardAction && !isCardChild && (
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.DESTRUCTIVE_SOLID}
                    onClick={handleOpenModal}
                    className={styles.cardAction}
                    icon={IconType.TRASH}
                    disabled={disabled}
                    type="button"
                >
                    {props.removeLabel}
                </Button>
            )}

            {!cardOpen && !isCardChild && (
                <div className={styles.expandLabel}>
                    {localization.getString("expandToSeeDetails")}
                </div>
            )}

            {isCardChild && (
                <div id={titleId} className={styles.collapseLink}>
                    <button
                        onClick={() => handleCardOpen(!cardOpen)}
                        className={styles.toggleCardOpen}
                        type="button"
                    >
                        <Icon icon={cardOpen ? IconType.CARET_DOWN : IconType.CARET_RIGHT} />
                        {title}
                    </button>
                </div>
            )}

            <div className={cardContentClass}>
                <div>{children}</div>
            </div>

            <ConfirmationModal
                id={Id.REMOVE_DIALOG}
                title={props.removeLabel}
                onConfirm={handleConfirmRemove}
                confirmButtonLabel={localization.getString("yesRemove")}
                isDestructive
                ref={modalRef}
            >
                {props.removeMessage}
            </ConfirmationModal>
        </fieldset>
    )
}

enum Id {
    REMOVE_DIALOG = "removeDialog",
}

const ConfirmationModal = React.forwardRef(ConfirmationModalWithoutRef)
