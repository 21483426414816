import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"

@Singleton("PolicyApi")
export class PolicyApi extends BaseApi {
    public getPolicyAttachments(): Promise<PolicyAttachmentRes[]> {
        return this.get("/api/v1/policy/attachment", {
            default: this.localization.getString("errorCouldNotGetPolicyAttachments"),
        })
    }

    public getPolicyAttachmentByServiceId(serviceId: string): Promise<PolicyAttachmentRes[]> {
        return this.get(`/api/v1/policy/attachment/service/${encodeURIComponent(serviceId)}`, {
            default: this.localization.getString("errorCouldNotGetPolicyAttachments"),
        })
    }

    public setPolicyAttachment(
        policyId: string,
        serviceId: string,
        enabled: boolean
    ): Promise<PolicyAttachmentRes> {
        return this.postForm(
            "/api/v1/insert_security_attach_policy",
            {
                PolicyID: policyId,
                ServiceID: serviceId,
                Enabled: enabled ? "TRUE" : "FALSE",
            },
            {
                default: this.localization.getString("failedToAttachPolicy"),
            }
        )
    }

    public deletePolicyAttachment(policyId: string, serviceId: string): Promise<void> {
        return this.delete(
            `/api/v1/delete_security_attach_policy?PolicyID=${encodeURIComponent(
                policyId
            )}&ServiceID=${encodeURIComponent(serviceId)}`,
            {},
            {
                default: this.localization.getString("errorDetachingServiceFromPolicy"),
            }
        )
    }

    public getPolicies(searchParams?: PolicySearchParams): Promise<PolicyRes[]> {
        const params = UrlUtil.convertToURLSearchParams({ roleID: searchParams?.roleID })

        return this.get(`/api/v1/security_policies?${params.toString()}`, {
            default: this.localization.getString("failedToLoadPolicies"),
        })
    }

    public getPolicyById(policyId: string): Promise<PolicyRes[]> {
        return this.get("/api/v1/security_policies?PolicyID=" + encodeURIComponent(policyId), {
            default: this.localization.getString("failedToLoadPolicies"),
        })
    }

    public insertPolicy(spec: PolicyReq): Promise<PolicyRes> {
        return this.post("/api/v1/insert_security_policy", spec, {
            default: this.localization.getString("failedToCreateUpdatePolilcy"),
        })
    }

    public deletePolicy(id: string): Promise<void> {
        return this.delete(
            "/api/v1/delete_security_policy?PolicyID=" + id,
            {},
            {
                default: this.localization.getString("failedToDeletePolicy"),
            }
        )
    }
}

export interface PolicyAttachmentRes {
    PolicyID: string
    PolicyName: string
    AttachedToName: string
    AttachedToID: string
    Enabled: "TRUE" | "FALSE"
    AttachedAt: number
}

export interface PolicyRes {
    LastUpdatedAt: number
    PolicyID: string
    PolicyName: string
    PolicySpec: string
    CreatedAt: number
    CreatedBy: string
    Description: string
    LastUpdatedBy: string
    PolicyVersion: number
}

export interface PolicySpecRes {
    access: PolicyAccessRes[]
    options?: {
        l7_protocol: string
    }
}

interface PolicyAccessRes {
    name?: string
    description?: string
    roles: string[]
    rules: PolicyRulesRes
}

interface PolicyRulesRes {
    l4_access?: PolicyL4AccessRes
    conditions: PolicyConditionsRes
}

interface PolicyConditionsRes {
    trust_level?: string
}

interface PolicyL4AccessRes {
    deny?: L4RuleRes[]
    allow: L4RuleRes[]
}

interface L4RuleRes {
    cidrs: string[]
    protocols: L4Protocol[]
    ports: string[]
    fqdns: string[]
    description: string
}

export interface PolicySearchParams {
    roleID?: string
}

export interface PolicyReq {
    kind: string
    apiVersion: string
    metadata: {
        id?: string
        name: string
        description: string
        tags: StringMap
    }
    type: PolicyTypeReq
    spec: PolicySpecReq
}

export interface PolicySpecReq {
    access: AccessReq[]
    options?: {
        l7_protocol: string
    }
}

export interface AccessReq {
    name?: string
    description?: string
    roles: string[]
    rules: {
        l4_access?: {
            deny: L4RuleReq[]
            allow: L4RuleReq[]
        }
        conditions: {
            trust_level?: string
        }
    }
}
interface L4RuleReq {
    cidrs: string[]
    protocols: L4Protocol[]
    ports: string[]
    fqdns: string[]
    description?: string
}

export enum L4Protocol {
    TCP = "TCP",
    UDP = "UDP",
    ICMP = "ICMP",
    ALL = "ALL",
}

export type PolicyTypeReq = "CUSTOM" | "USER"
